<template>
  <article class="charge">
    <h1>{{ ticket.companyName }}</h1>
    <section class="content">
      <h2>{{ ticket.name }}</h2>
      <ul class="select cf">
        <li>
          <img :src="ticket.coverImage" style="width:150px;height:150px" />
        </li>
      </ul>
      <div class="price">
        票数：<strong>{{ order.ticketCount }}张</strong>
      </div>
      <div class="price">
        总价：<strong>{{ order.totalPrice }}元</strong>
      </div>
      <div class="operation">
        <a class="btn-green" @click="targetHandle">立即购买</a>
      </div>
    </section>
  </article>
</template>

<script>
import { getOrderDetail, getTicketDetail, getWxUlr } from "@/api/index.js";

export default {
  name: "wxpay",
  data() {
    return {
      orderId: "",
      ticket: {},
      order: {},
      appId: "",
      nonceStr: "",
      packageValue: "",
      paySign: "",
      signType: "",
      timeStamp: "",
    };
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    this.getOrderInfo();
    this.applyWxPay();
  },
  methods: {
    applyWxPay() {
      var fp = new window.Fingerprint2();
      fp.get(result => {
        window.$.getJSON(getWxUlr(this.orderId, result), d => {
          if (d.appId) {
            this.appId = d.appId;
            this.nonceStr = d.nonceStr;
            this.packageValue = d.packageValue;
            this.paySign = d.paySign;
            this.signType = d.signType;
            this.timeStamp = d.timeStamp;
          } else {
            this.$message.warning(d.message);
          }
        });
      });
    },
    onBridgeReady() {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.appId,   //公众号ID，由商户传入
        "timeStamp": this.timeStamp,   //时间戳，自1970年以来的秒数
        "nonceStr": this.nonceStr,      //随机串
        "package": this.packageValue,
        "signType": this.signType,     //微信签名方式：
        "paySign": this.paySign //微信签名
      }, function(res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          window.location.href = "/mine";
        } else {
          alert("支付失败！");
        }
      });
    },
    targetHandle() {
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    getOrderInfo() {
      let orderId = this.$route.query.orderId;
      getOrderDetail(orderId).then(res => {
        if (res) {
          this.order = res;
          this.getTicketInfo(res.ticketId);
        }
      });
    },
    getTicketInfo(ticketId) {
      getTicketDetail(ticketId).then(res => {
        if (res) {
          this.ticket = res;
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
h1,h2{font-size:100%;}
ul{list-style:none;}
body{-webkit-user-select:none;-webkit-text-size-adjust:none;font-family:Helvetica;background:#ECECEC;}
html,body{height:100%;}
a,button,input,img{-webkit-touch-callout:none;outline:none;}
a{text-decoration:none;}
/* 重置 ]]*/
/* 功能 [[*/
.hide{display:none!important;}
.cf:after{content:".";display:block;height:0;clear:both;visibility:hidden;}
/* 功能 ]]*/
/* 按钮 [[*/
a[class*="btn"]{display:block;height:42px;line-height:42px;color:#FFFFFF;text-align:center;border-radius:5px;}
.btn-blue{background:#3D87C3;border:1px solid #1C5E93;}
.btn-green{background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, #43C750), color-stop(1, #31AB40));border:1px solid #2E993C;box-shadow:0 1px 0 0 #69D273 inset;}
/* 按钮 [[*/
/* 充值页 [[*/
.charge{font-family:Helvetica;padding-bottom:10px;-webkit-user-select:none; position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 100; background-color: #ffffff;overflow: auto;}
.charge h1{height:44px;line-height:44px;color:#FFFFFF;background:#3D87C3;text-align:center;font-size:20px;-webkit-box-sizing:border-box;box-sizing:border-box;}
.charge h2{font-size:14px;color:#777777;margin:5px 0;text-align:center;}
.charge .content{padding:10px 12px;}
.charge .select li{position:relative;display:block;float:left;width:100%;margin-right:2%;height:150px;line-height:150px;text-align:center;color:#666666;font-size:16px;margin-bottom:5px;border-radius:3px;-webkit-box-sizing:border-box;box-sizing:border-box;overflow:hidden;}
.charge .price{border-bottom:1px dashed #C9C9C9;padding:10px 10px 15px;margin-bottom:20px;color:#666666;font-size:12px;}
.charge .price strong{font-weight:normal;color:#EE6209;font-size:26px;font-family:Helvetica;}
.charge .showaddr{border:1px dashed #C9C9C9;padding:10px 10px 15px;margin-bottom:20px;color:#666666;font-size:12px;text-align:center;}
.charge .showaddr strong{font-weight:normal;color:#9900FF;font-size:26px;font-family:Helvetica;}
.charge .copy-right{margin:5px 0; font-size:12px;color:#848484;text-align:center;}
</style>
